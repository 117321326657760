import { ReactEventHandler, useEffect, useRef } from 'react';
import Link from 'next/link';
import { noop } from 'lodash-es';

import { Box, chakra, Flex, Heading, Text, useToken } from '@chakra-ui/react';

import { ButtonLink } from '~/components/button-link';
import { ContentContainer } from '~/components/container';
import { Glyph } from '~/components/glyph';
import { HeadingWithEmphasis } from '~/components/heading-with-emphasis';
import { spaceHeight } from '~/components/space';
import { trackEvent } from '~/features/analytics/track-event';
import { useExperiment } from '~/features/experiments/hooks/use-experiment';
import { TemplateButton as TemplateButtonType } from '~/features/page-template/models/template-button';

export type HeroSectionProps = {
  title: string;
  subtitle?: string;
  primaryCta?: TemplateButtonType;
  secondaryCta?: TemplateButtonType;
  onSchoolCardClick?: (schoolTitle: string) => void;
  isFreeTrialOn?: boolean;
};

export function HeroSection({
  title,
  subtitle,
  primaryCta,
  secondaryCta,
  onSchoolCardClick,
  isFreeTrialOn = false,
}: HeroSectionProps) {
  const experiment = useExperiment();
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    // Note: this effect is an optimization to improve Lighthouse scores.
    // We don't really need the video to load until after the page has loaded since we have a poster to render in place of the video.
    const video = videoRef.current;
    if (video) {
      for (let source in video.children) {
        const videoSource = video.children[source] as HTMLSourceElement;
        if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
          videoSource.src = videoSource.dataset.src as string;
        }
      }
      video.load();
    }
  }, []);

  const playVideo: ReactEventHandler<HTMLVideoElement> = (e) => {
    if (e.currentTarget.readyState >= 0) {
      e.currentTarget.play().catch(noop);
    }
  };

  const isTreatment = experiment?.ab_hp_schools ? ['variant_b', 'variant_c'].includes(experiment.ab_hp_schools) : false;

  return (
    <Box
      as='section'
      bgGradient='linear(to-b, blue.800, blue.800)'
      overflow='hidden'
      paddingY={spaceHeight}
      position='relative'
    >
      <Box bottom='0px' height='100%' left='50%' position='absolute' transform='translateX(-50%)' width='100%'>
        <chakra.video
          ref={videoRef}
          bottom='0px'
          height='100%'
          left='0px'
          objectFit='cover'
          pointerEvents='none'
          position='absolute'
          poster='/videos/waves-poster.webp'
          right='0px'
          top='0px'
          width='100%'
          sx={{
            '&::-webkit-media-controls-start-playback-button': {
              display: 'none !important',
            },
          }}
          loop
          muted
          playsInline
          onLoadedMetadata={playVideo}
          onSuspend={playVideo}
        >
          <source data-src='/videos/waves.webm' type='video/webm' />
          <source data-src='/videos/waves-h265.mp4' type='video/mp4' />
          <source data-src='/videos/waves-h264.mp4' type='video/mp4' />
        </chakra.video>
      </Box>
      {isTreatment && <Box bgColor='#0F1139' inset={0} opacity={0.5} pointerEvents='none' position='absolute' />}
      <Flex flexDir='column' position='relative'>
        <ContentContainer color='white' textAlign='center'>
          <HeadingWithEmphasis
            emphasisProps={isTreatment ? { color: 'accent.lime', fontWeight: '500' } : undefined}
            text={title}
            headingProps={{
              size: {
                base: 'marketing-2xl',
                md: 'marketing-4xl',
              },
              whiteSpace: 'pre-wrap',
              fontWeight: '400',
              as: 'h1',
              marginBottom: isFreeTrialOn ? 'unset' : '16px',
            }}
          />
        </ContentContainer>
        {isFreeTrialOn && (
          <>
            <Box h='40px' />
            <ContentContainer color='white' textAlign='center'>
              <ButtonLink
                buttonProps={{
                  colorScheme: 'sea-foam',
                  mb: 4,
                  onClick: () =>
                    trackEvent({
                      name: 'Free Trial CTA Clicked',
                      type: 'click',
                      label: '7 day free trial',
                      value: 'CTA 1',
                    }),
                }}
                linkProps={{
                  href: '/checkout/individual/monthly/credit-card',
                }}
              >
                Start a Free 7-Day Trial
              </ButtonLink>
              <Text fontWeight='500'>Try risk-free. Cancel anytime.</Text>
            </ContentContainer>
          </>
        )}
        <Box h='40px' />
        {!isTreatment && <SchoolCards onCardClick={onSchoolCardClick} />}
        {isTreatment && <TreatmentSchoolCards onCardClick={onSchoolCardClick} />}
      </Flex>
    </Box>
  );
}

export const schoolCards = [
  {
    label: 'Data Science',
    glyph: 'school-data-sci-one',
    path: '/school/data-science',
  },
  {
    label: 'Programming & Development',
    glyph: 'school-programming-one',
    path: '/school/programming',
  },
  {
    label: 'Artificial Intelligence',
    glyph: 'school-ai-one',
    path: '/school/artificial-intelligence',
  },
  {
    label: 'Business',
    glyph: 'school-business-one',
    path: '/school/business',
  },
  {
    label: 'Autonomous Systems',
    glyph: 'school-autonomous-sys-one',
    path: '/school/autonomous-systems',
  },
  {
    label: 'Product Management',
    glyph: 'school-product-mgt-one',
    path: '/school/product-management',
  },
  {
    label: 'Cloud Computing',
    glyph: 'school-cloud-computing-one',
    path: '/school/cloud-computing',
  },
];

export function SchoolCards({ onCardClick }: { onCardClick?: (schoolTitle: string) => void }) {
  const [seaFoam] = useToken('colors', ['accent.sea-foam.500']);

  return (
    <Box aria-label='schools navigation' as='nav' margin='auto' maxW={{ base: '100%', xl: '70rem' }}>
      <Flex as='ul' gap='16px' listStyleType='none' overflow='auto' paddingX={{ base: '16px', xl: 0 }}>
        {schoolCards.map((schoolCard) => {
          return (
            <chakra.li key={schoolCard.label} display='flex' flex='1'>
              <Flex
                alignItems='center'
                as={Link}
                bg='white'
                borderRadius='base'
                flex={1}
                flexDirection='column'
                href={schoolCard.path}
                justifyContent='center'
                minWidth='144px'
                overflow='auto'
                p='16px'
                width={0}
                _hover={{
                  boxShadow: `inset 0 0 0 3px ${seaFoam}`,
                }}
                onClick={() => onCardClick?.(schoolCard.label)}
              >
                <Glyph name={schoolCard.glyph} />
                <Text color='black' textAlign='center'>
                  {schoolCard.label}
                </Text>
              </Flex>
            </chakra.li>
          );
        })}
      </Flex>
    </Box>
  );
}

export function TreatmentSchoolCards({ onCardClick }: { onCardClick?: (schoolTitle: string) => void }) {
  const [seaFoam] = useToken('colors', ['accent.sea-foam.500']);
  const experiment = useExperiment();

  return (
    <Box aria-label='schools navigation' as='nav'>
      <Box
        borderBottom={{ md: '1px solid #F0F5FF4D' }}
        borderLeft={{ md: '1px solid #F0F5FF4D' }}
        borderRight={{ md: '1px solid #F0F5FF4D' }}
        borderTop='1px solid #F0F5FF4D'
        margin='0 auto'
        maxWidth='70rem'
        position='relative'
        borderRadius={{
          md: '12px',
        }}
      >
        <Flex
          as='ul'
          gap='12px'
          listStyleType='none'
          margin='0 auto'
          maxWidth='70rem'
          overflow='auto'
          position='relative'
          py={6}
          sx={{
            '&::before, &::after': {
              content: '""',
              display: 'block',
              flex: '0 0 1px',
            },
          }}
        >
          {schoolCards.map((schoolCard) => {
            return (
              <chakra.li key={schoolCard.label} display='flex'>
                <Flex
                  alignItems='center'
                  as={Link}
                  bg='white'
                  borderRadius='base'
                  flex={1}
                  flexDirection='column'
                  href={schoolCard.path}
                  justifyContent='center'
                  minWidth='144px'
                  overflow='auto'
                  p='16px'
                  width={0}
                  _hover={{
                    boxShadow: `inset 0 0 0 3px ${seaFoam}`,
                  }}
                  onClick={() => onCardClick?.(schoolCard.label)}
                >
                  <Glyph name={schoolCard.glyph} />
                  <Text color='black' textAlign='center'>
                    {schoolCard.label}
                  </Text>
                </Flex>
              </chakra.li>
            );
          })}
        </Flex>
        <Heading
          as='p'
          bgColor='#0F1139'
          color='white'
          display='block'
          fontSize='2rem'
          padding='0 24px'
          position='absolute'
          right='50%'
          transform='translateX(50%)'
          whiteSpace='nowrap'
          size={{
            base: 'marketing-md',
            md: 'marketing-lg',
          }}
          top={{
            base: '-13px',
            md: '-16px',
          }}
        >
          {experiment?.ab_hp_schools === 'variant_b' && <>Find the right learning path for you</>}
          {experiment?.ab_hp_schools === 'variant_c' && <>Explore courses by job function</>}
        </Heading>
      </Box>
    </Box>
  );
}
